import React from 'react'
import PropTypes from 'prop-types'
import { Tween, ScrollTrigger } from 'react-gsap'
import tw, { css, styled } from 'twin.macro'
import { toRem } from '../styles'
import { colors } from '../../config/design-system'

const asideStyles = css`
  ${tw`hidden h-full absolute top-0 lg:flex z-10`};
  max-width: 14vw;
  background: linear-gradient(90deg, ${colors['dark-bg-color']} 0%, rgba(18, 18, 18, 0) 100%);
`

const navStyles = css`
  ${tw`flex  flex-col justify-center h-screen sticky top-0`};
`

const activeListItemStyles = css`
  &::after {
    ${tw`opacity-100`}
    transform: translate3d(0%, 0, 0);
  }
`

const listItemStyles = css`
  ${tw`inline-flex relative pointer-events-none`};
  padding-left: 2.5vw;
  &:not(:last-child) {
    ${tw`mb-8`};
  }
  &::after {
    ${tw`w-full absolute bottom-0 left-0 bg-accent-color opacity-0`};
    content: '';
    height: ${toRem(1)};
    transform: translate3d(-100%, 0, 0);
    transition: opacity 0.2s, transform 0.2s;
  }
`

const activeLinkStyles = css`
  ${tw`text-accent-color`};
`

const StyledLink = styled.a`
  ${tw`relative font-thin text-light-text-color text-center pointer-events-auto text-left`};
  transition: 0.2s ease-in-out;
  &:hover {
    ${activeLinkStyles};
  }
  ${({ active }) => active && activeLinkStyles}
`

const btnStyles = css`
  ${tw`flex items-center justify-center border border-light-bg-color p-1 mt-20 cursor-pointer bg-transparent`};
  width: ${toRem(42)};
  height: ${toRem(42)};
  margin-left: 2.5vw;
  transition: 0.15s ease-in-out;
  &:hover,
  &:focus {
    ${tw`border-accent-color`};

    & > svg {
      stroke: ${colors['accent-color']};
    }
  }
`
const arrowStyles = css`
  stroke: ${colors['light-bg-color']};
  margin-top: 2px;
  transform: translateY(-0.1rem);
  transition 0.15s ease-in-out;
`

const NavBar = ({ links, startSectionId, sectionsState }) => (
  <nav css={navStyles}>
    <ul tw="flex flex-col items-baseline">
      {links.map(({ title, id }) => {
        const active = sectionsState[id]
        return (
          <li css={[listItemStyles, active && activeListItemStyles]} key={id}>
            <StyledLink active={active} href={`#${id}`}>
              {title}
            </StyledLink>
          </li>
        )
      })}
    </ul>

    <button css={btnStyles} onClick={() => window.scrollTo({ top: 0, behaviour: 'smooth' })}>
      <svg css={arrowStyles} width="22" height="26" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.05 11.925 10.724 1.6.398 11.925M10.63 25.18V2.044" />
      </svg>
    </button>
  </nav>
)

const AnchorMenu = ({ startSectionId, ...props }) => {
  return startSectionId ? (
    <ScrollTrigger trigger={`#${startSectionId}`} start="top 50%" end="top 20%" scrub>
      <Tween from={{ opacity: 0, visibility: 'hidden' }} to={{ opacity: 1, visibility: 'visible' }}>
        <aside css={asideStyles}>
          <NavBar {...props} />
        </aside>
      </Tween>
    </ScrollTrigger>
  ) : (
    <aside css={asideStyles}>
      <NavBar {...props} />
    </aside>
  )
}

AnchorMenu.propTypes = {
  links: PropTypes.array.isRequired,
  sectionsState: PropTypes.object.isRequired,
  startSectionId: PropTypes.string
}

export default AnchorMenu
